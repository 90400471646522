import { ProfileActionTypes } from './types';

export const refreshProfileState = () => ({ 
  type: ProfileActionTypes.REFRESH_PROFILE_STATE
});

export const getContracts = (params) => ({ 
  type: ProfileActionTypes.GET_CONTRACTS_REQUEST, 
  params 
});

export const getContractsSuccess = (params) => ({ 
  type: ProfileActionTypes.GET_CONTRACTS_SUCCESS, 
  params 
});

export const getContractsFailure = (params) => ({ 
  type: ProfileActionTypes.GET_CONTRACTS_FAILURE, 
  params 
});

export const getAccountStatus = (params) => ({ 
  type: ProfileActionTypes.GET_ACCOUNT_STATUS_REQUEST, 
  params 
});

export const getAccountStatusSuccess = (params) => ({ 
  type: ProfileActionTypes.GET_ACCOUNT_STATUS_SUCCESS, 
  params 
});

export const getAccountStatusFailure = (params) => ({ 
  type: ProfileActionTypes.GET_ACCOUNT_STATUS_FAILURE, 
  params 
});

export const getCreditLine = (params) => ({ 
  type: ProfileActionTypes.GET_CREDIT_LINE_REQUEST, 
  params 
});

export const getCreditLineSuccess = (params) => ({ 
  type: ProfileActionTypes.GET_CREDIT_LINE_SUCCESS, 
  params 
});

export const getCreditLineFailure = (params) => ({ 
  type: ProfileActionTypes.GET_CREDIT_LINE_FAILURE, 
  params 
});


export const getUserFlags = (params) => ({ 
  type: ProfileActionTypes.GET_USER_FLAGS_REQUEST, 
  params 
});

export const getUserFlagsSuccess = (params) => ({ 
  type: ProfileActionTypes.GET_USER_FLAGS_SUCCESS, 
  params 
});

export const getUserFlagsFailure = (params) => ({ 
  type: ProfileActionTypes.GET_USER_FLAGS_FAILURE, 
  params 
});