import { RegistrationSteps } from '@src/api/ProfileService/DTO/client';

import { SIGN_UP_ACTION_TYPES } from './types';

export function setInfoUser(payload: any) {
  return { type: SIGN_UP_ACTION_TYPES.SET_INFO_USER, payload };
}
export function setPhone(payload: string) {
  return { type: SIGN_UP_ACTION_TYPES.SET_PHONE, payload };
}
export function setPhoneCode(payload: string) {
  return { type: SIGN_UP_ACTION_TYPES.SET_PHONE_CODE, payload };
}
export function setPassword(payload: string) {
  return { type: SIGN_UP_ACTION_TYPES.SET_PASSWORD, payload };
}
export function setStep(payload: RegistrationSteps) {
  return { type: SIGN_UP_ACTION_TYPES.SET_STEP, payload };
}

export function setStepQty(payload: number) {
  return { type: SIGN_UP_ACTION_TYPES.SET_STEP_QTY, payload };
}
