import Keycloak from 'keycloak-js';
import { AUTH_SERVICE_HOST, KEYCLOAK_REALM, KEYCLOAK_CLIENT } from '@src/constants';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: `${AUTH_SERVICE_HOST}/auth`,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT
});

export default keycloak;
