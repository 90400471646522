import React, { lazy, Suspense, useEffect} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { configureAnchors } from 'react-scrollable-anchor';
import 'normalize.css';
import { keycloakInit } from '@src/store/ducks/auth';
import '@src/App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '@src/pages/SignUpV2/components/Loader';

const Router = lazy(() => import('@src/router/Router'));
configureAnchors({ offset: -65 });

function App() {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useSelector(({ auth }) => ({
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
  }));
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (initialized && accessToken && refreshToken) {
      dispatch(
        keycloakInit({
          accessToken,
          refreshToken,
        })
      );
    }
  }, [initialized]);

  useEffect(() => {
    const jdivs = document.getElementsByTagName('jdiv');
    if (!(jdivs && jdivs[0])) {
      /* global loadScript */
      loadScript('//code.jivosite.com/widget/JfXF52JtUk');
    } else {
      jdivs[0].removeAttribute('style');
    }

    return () => {
      const jdivs = document.getElementsByTagName('jdiv');
      if (jdivs && jdivs[0]) {
        jdivs[0].setAttribute('style', 'display: none');
      }
    };
  }, []);

  return (
    <div className="app" id="app">
      <Suspense fallback={<Loader />}>
        <Router />
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
