export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );

  if (matches) {
    return decodeURIComponent(matches[1])
  }
};

export interface ICookiesOptions {
  path?: string
  domain?: string
  expires?: Date
  'max-age'?: number
  secure?: boolean
  samesite?: 'strict' | 'lax'
}

export const setCookie = (
  name: string,
  value: string,
  options: ICookiesOptions = {},
) => {

  const defaultOptions: ICookiesOptions = {
    path: '/',
  }

  const cookiesOptions = {
    ...defaultOptions,
    ...options,
  ...(options.expires ? { expires: options.expires.toUTCString() } : {}),
  }

  const updatedCookie = (Object.keys(cookiesOptions) as (keyof ICookiesOptions)[])
    .reduce(
      (updatedCookie, key) => {
        if (['string', 'number'].includes(typeof cookiesOptions[key])) {
          return `${updatedCookie}; ${key}=${cookiesOptions[key]}`
        }

        if (typeof cookiesOptions[key] === 'boolean' && cookiesOptions[key]) {
          return `${updatedCookie}; ${cookiesOptions[key]}`
        }

        return updatedCookie
      },
      `${encodeURIComponent(name)} = ${encodeURIComponent(value)}`,
    )
  document.cookie = updatedCookie
}

export const deleteCookie = (
  name: string,
  options: Pick<ICookiesOptions, 'path' | 'domain'> = {},
) => {
  setCookie(name, '', { ...options, 'max-age': -1 })
};

export const checkCookie = () =>
  !!(getCookie('refresh_token') && getCookie('token'));
