import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';

import { ProfileActionTypes } from './types';
import { AuthActionTypes } from '../auth/types';

export const initialState = {
  contracts: [],
  accountModerationStatus: null,
  creditLine: {
    userCreditLimit: 0,
    usedLimit: 0,
    systemCreditLimit: 0,
  },
  flags: {
    profileFlags: {
      addresses: false,
      documents: false,
      passports: false,
      pdns: false,
      saltedge: false,
    },
    registration: false,
    training: false,
  },
  loading: false,
  error: '',
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProfileActionTypes.GET_CONTRACTS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case ProfileActionTypes.GET_CONTRACTS_SUCCESS: {
      return {
        ...state,
        contracts: action.params,
        loading: false,
      };
    }

    case ProfileActionTypes.GET_CONTRACTS_FAILURE: {
      return {
        ...state,
        contracts: [],
        loading: false,
        error: action.params,
      };
    }

    case ProfileActionTypes.GET_ACCOUNT_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case ProfileActionTypes.GET_ACCOUNT_STATUS_SUCCESS: {
      return {
        ...state,
        accountModerationStatus: action.params.result,
        loading: false,
      };
    }

    case ProfileActionTypes.GET_ACCOUNT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.params,
      };
    }

    case ProfileActionTypes.GET_CREDIT_LINE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case ProfileActionTypes.GET_CREDIT_LINE_SUCCESS: {
      return {
        ...state,
        creditLine: action.params,
        loading: false,
      };
    }

    case ProfileActionTypes.GET_CREDIT_LINE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.params,
      };
    }

    case ProfileActionTypes.GET_USER_FLAGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case ProfileActionTypes.GET_USER_FLAGS_SUCCESS: {
      return {
        ...state,
        flags: action.params,
        loading: false,
      };
    }

    case ProfileActionTypes.GET_USER_FLAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.params,
      };
    }

    case ProfileActionTypes.REFRESH_PROFILE_STATE:
    case AuthActionTypes.LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
