export enum SIGN_UP_ACTION_TYPES {
  SET_INFO_USER = '@@sign_up/SET_INFO_USER',
  SET_PHONE = '@@sign_up/SET_PHONE',
  SET_PHONE_CODE = '@@sign_up/SET_PHONE_CODE',
  SET_PASSWORD = '@@sign_up/SET_PASSWORD',
  SET_FULL_NAME = '@@sign_up/SET_FULL_NAME',
  SET_PASSPORT_DATA = '@@sign_up/SET_PASSPORT_DATA',
  SET_BIRTH_INFO = '@@sign_up/SET_BIRTH_INFO',
  SET_STEP = '@@sign_up/SET_STEP',
  SET_STEP_QTY = '@@sign_up/SET_STEP_QTY',
}
