import { AuthActionTypes } from './types';

export const initialState = {
  errors: [],
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  username: ''
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return {
        ...initialState,
        username: action.params.username
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return state;
    }
    case AuthActionTypes.LOGIN_ERROR: {
      return {
        ...state,
        username: '',
        errors: [action.message],
      };
    }

    case AuthActionTypes.KEYCLOAK_INIT: {
      return state;
    }
    case AuthActionTypes.KEYCLOAK_INIT_SUCCESS: {
      return {
        ...state,
        errors: [],
        accessToken: action.params.accessToken,
        refreshToken: action.params.refreshToken,
        isAuthenticated: true
      };
    }
    case AuthActionTypes.KEYCLOAK_INIT_ERROR: {
      return {
        ...state,
        errors: [action.message],
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false
      };
    }

    case AuthActionTypes.LOGOUT: {
      return state;
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }
    case AuthActionTypes.LOGOUT_ERROR: {
      return {
        ...state,
        errors: [action.message],
      };
    }
    default:
      return state;
  }
};
