import React, { useCallback } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { logout } from '@src/store/ducks/auth';

import keycloak from './keycloak';

export const KeycloakProvider = ({ children }) => {
  const dispatch = useDispatch();

  const handleEvent = useCallback((event, error) => {
    if (event === 'onAuthLogout') {
      dispatch(
        logout()
      );
    }
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={handleEvent}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProvider;