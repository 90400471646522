export const ProfileActionTypes = {
  REFRESH_PROFILE_STATE: '@@profile/REFRESH_PROFILE_STATE',

  GET_CONTRACTS_REQUEST: '@@profile/GET_CONTRACTS_REQUEST',
  GET_CONTRACTS_SUCCESS: '@@profile/GET_CONTRACTS_SUCCESS',
  GET_CONTRACTS_FAILURE: '@@profile/GET_CONTRACTS_FAILURE',
  
  GET_ACCOUNT_STATUS_REQUEST: '@@profile/GET_ACCOUNT_STATUS_REQUEST',
  GET_ACCOUNT_STATUS_SUCCESS: '@@profile/GET_ACCOUNT_STATUS_SUCCESS',
  GET_ACCOUNT_STATUS_FAILURE: '@@profile/GET_ACCOUNT_STATUS_FAILURE',

  GET_CREDIT_LINE_REQUEST: '@@profile/GET_CREDIT_LINE_REQUEST',
  GET_CREDIT_LINE_SUCCESS: '@@profile/GET_CREDIT_LINE_SUCCESS',
  GET_CREDIT_LINE_FAILURE: '@@profile/GET_CREDIT_LINE_FAILURE',

  GET_USER_FLAGS_REQUEST: '@@profile/GET_USER_FLAGS_REQUEST',
  GET_USER_FLAGS_SUCCESS: '@@profile/GET_USER_FLAGS_SUCCESS',
  GET_USER_FLAGS_FAILURE: '@@profile/GET_USER_FLAGS_FAILURE',
};
