import {
  all,
  call,
  fork,
  put,
  race,
  take,
  takeLatest,
} from 'redux-saga/effects';
import keycloak from '@src/keycloak/keycloak';
import { FRONT_HOST } from '@src/constants';
import {login, logout, profileManagerLogout, refreshTokenPost} from '@src/api';
import { deleteCookie, setCookie } from '@src/utils/cookies';
import { getUserFlags } from '@src/store/ducks/profile';

import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  keycloakInit,
  keycloakInitSuccess,
  keycloakInitError,
} from './actions';
import { AuthActionTypes } from './types';

/**
 * @desc Business logic of effect.
 */
function* handleLogin(action) {
  try {
    const { result, error } = yield call(login, action.params);

    if (error?.response?.data) {
      throw new Error(error?.response?.data?.error_description);
    } else {
      yield put(
        keycloakInit({
          accessToken: result?.data?.access_token,
          refreshToken: result?.data?.refresh_token,
        })
      );

      const { success, error } = yield race({
        success: take(AuthActionTypes.KEYCLOAK_INIT_SUCCESS),
        error: take(AuthActionTypes.KEYCLOAK_INIT_ERROR),
      });

      if (error) {
        throw new Error(error.message);
      } else if (success) {
        window.dataLayer.push({'event': 'auth'});
        yield put(loginSuccess());
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(loginError(err.message));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleRefreshToken() {
  try {
    if (keycloak.tokenParsed && Date.now() >= keycloak.tokenParsed.exp * 1000) {
      const {result, error} = yield call(refreshTokenPost);

      if (error?.response?.data) {
        throw new Error(error?.response?.data?.error_description);
      } else {
        yield put(
            keycloakInit({
              accessToken: result?.data?.access_token,
              refreshToken: result?.data?.refresh_token,
            })
        );

        const { success, error } = yield race({
          success: take(AuthActionTypes.KEYCLOAK_INIT_SUCCESS),
          error: take(AuthActionTypes.KEYCLOAK_INIT_ERROR),
        });

        if (error) {
          throw new Error(error.message);
        } else if (success) {
          window.dataLayer.push({'event': 'auth'});
          yield put(loginSuccess());
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(keycloakInitError(err.message));
    } else {
      yield put(keycloakInitError('An unknown error occured.'));
    }
  }
}

function* handleKeycloakInit(action) {
  try {
    yield call(keycloak.init, {
      token: action.params.accessToken,
      refreshToken: action.params.refreshToken,
      checkLoginIframe: false,
    });

    if (keycloak.authenticated) {
      yield put(
        keycloakInitSuccess({
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
        })
      );

      setCookie('ACCESS_TOKEN', keycloak.token, {
        'max-age': 31536000,
      });
      setCookie('REFRESH_TOKEN', keycloak.refreshToken, {
        'max-age': 31536000,
      });
    } else {
      throw new Error('Unauthorized');
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(keycloakInitError(err.message));
    } else {
      yield put(keycloakInitError('An unknown error occured.'));
    }
  }
}

function* handleKeycloakInitSuccess() {
  yield put(getUserFlags());
}

function* handleLogout(action) {
  try {
    yield call(profileManagerLogout);
    yield call(logout);

    yield put(logoutSuccess());
    deleteCookie('ACCESS_TOKEN');
    deleteCookie('REFRESH_TOKEN');

    yield call(keycloak.logout, {
      redirectUri: new URL(action.params.redirectUri || '', FRONT_HOST).href,
    });
  } catch (err) {
    if (err instanceof Error) {
      yield put(logoutError(err.message));
    } else {
      yield put(logoutError('An unknown error occured.'));
    }
  }
}

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchUserAuthentication() {
  yield takeLatest(AuthActionTypes.LOGIN, handleLogin);
  yield takeLatest(AuthActionTypes.LOGOUT, handleLogout);
  yield takeLatest(AuthActionTypes.KEYCLOAK_INIT, handleKeycloakInit);
  yield takeLatest(
    AuthActionTypes.KEYCLOAK_INIT_SUCCESS,
    handleKeycloakInitSuccess
  );
  yield takeLatest(
      AuthActionTypes.KEYCLOAK_REFRESH_TOKEN,
      handleRefreshToken
  )
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* authSaga() {
  yield all([fork(watchUserAuthentication)]);
}
