import { AuthActionTypes } from './types';

export const login = (params) => ({ 
  type: AuthActionTypes.LOGIN, 
  params 
});
export const loginSuccess = (params) => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  params
});
export const loginError = (message) => ({
  type: AuthActionTypes.LOGIN_ERROR,
  message,
});

export const keycloakInit = (params) => ({
  type: AuthActionTypes.KEYCLOAK_INIT,
  params
});
export const keycloakInitSuccess = (params) => ({
  type: AuthActionTypes.KEYCLOAK_INIT_SUCCESS,
  params
});
export const keycloakInitError = (message) => ({
  type: AuthActionTypes.KEYCLOAK_INIT_ERROR,
  message,
});

export const logout = (params = {}) => ({ type: AuthActionTypes.LOGOUT, params });
export const logoutSuccess = () => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
});
export const logoutError = (message) => ({
  type: AuthActionTypes.LOGOUT_ERROR,
  message,
});

export const refreshToken = (params = {}) => ({ type: AuthActionTypes.KEYCLOAK_REFRESH_TOKEN, params });

