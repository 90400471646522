import { LayoutActionTypes } from './types';

const initialState = {
  isLeftMenuOpened: false,
  isNavigationLocked: false,
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.OPEN_LEFT_MENU: {
      return {
        ...state,
        isLeftMenuOpened: true
      };
    }
    case LayoutActionTypes.CLOSE_LEFT_MENU: {
      return {
        ...state,
        isLeftMenuOpened: false
      };
    }
    case LayoutActionTypes.LOCK_NAVIGATION: {
      return {
        ...state,
        isNavigationLocked: true
      };
    }
    case LayoutActionTypes.UNLOCK_NAVIGATION: {
      return {
        ...state,
        isNavigationLocked: false
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;
