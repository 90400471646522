import { PaymentActionTypes } from './types';

export const fetchInfo = (payload) => ({
  type: PaymentActionTypes.FETCH_INFO,
  payload,
});
export const fetchInfoSuccess = (data) => ({
  type: PaymentActionTypes.FETCH_INFO_SUCCESS,
  data,
});
export const fetchInfoError = (message) => ({
  type: PaymentActionTypes.FETCH_INFO_ERROR,
  message,
});
