import { PaymentActionTypes } from './types';

export const initialState = {
  info: {},
  errors: [],
  loading: false,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentActionTypes.FETCH_INFO: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.FETCH_INFO_SUCCESS: {
      return { ...initialState, loading: false, info: action.data };
    }
    case PaymentActionTypes.FETCH_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        errors: [action.message],
      };
    }
    default:
      return state;
  }
};
