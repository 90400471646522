import React from 'react';

import styles from './Loader.module.scss';

export default function Loader() {
  return (
    <div className={styles.loader}>
      <div className={styles.points}>
        <span className={`${styles.point} ${styles.first}`} />
        <span className={`${styles.point} ${styles.second}`} />
        <span className={`${styles.point} ${styles.third}`} />
        <span className={`${styles.point} ${styles.forth}`} />
      </div>
      <div className={styles.title}>Загрузка данных</div>
    </div>
  );
}
