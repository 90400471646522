export const AuthActionTypes = {
  LOGIN: '@@auth/LOGIN',
  LOGIN_SUCCESS: '@@auth/LOGIN_SUCCESS',
  LOGIN_ERROR: '@@auth/LOGIN_ERROR',
  LOGOUT: '@@auth/LOGOUT',
  LOGOUT_SUCCESS: '@@auth/LOGOUT_SUCCESS',
  LOGOUT_ERROR: '@@auth/LOGOUT_ERROR',
  SIGNUP: '@@auth/SIGNUP',
  SIGNUP_SUCCESS: '@@auth/SIGNUP_SUCCESS',
  SIGNUP_ERROR: '@@auth/SIGNUP_ERROR',
  KEYCLOAK_INIT: '@@auth/KEYCLOAK_INIT',
  KEYCLOAK_INIT_SUCCESS: '@@auth/KEYCLOAK_INIT_SUCCESS',
  KEYCLOAK_INIT_ERROR: '@@auth/KEYCLOAK_INIT_ERROR',
  KEYCLOAK_REFRESH_TOKEN: '@@auth/KEYCLOAK_REFRESH_TOKEN',
};
