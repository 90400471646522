import * as axios from 'axios';

import keycloak from '../keycloak/keycloak';

export const apiCallerFetch = (method, url, params) => {
  const { data = {}, contentType = 'application/json' } = params || {};

  return axios({
    url,
    method,
    headers: {
      'Content-Type': contentType,
      Accept: '*/*',
      authorization: `Bearer ${keycloak.token}`,
    },
    data,
  });
};
