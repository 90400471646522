import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { authSaga, authReducer } from './auth';
import { profileSaga, profileReducer } from './profile';
import { paymentSaga, paymentReducer } from './payment';
import { signUpReducer } from './sign_up';
import { layoutReducer } from './layout';

export const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  payment: paymentReducer,
  profile: profileReducer,
  signUp: signUpReducer,
});

export function* rootSaga() {
  yield all([fork(authSaga), fork(paymentSaga), fork(profileSaga)]);
}
