import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { apiCallerFetch } from '@src/utils/api';
import { fetchInfoError, fetchInfoSuccess } from './actions';
import { PaymentActionTypes } from './types';

/**
 * @desc Business logic of effect.
 */
function* handleFetchInfo() {
  try {
    const URL = `${process.env.REACT_APP_PAYMENTS_API_HOST}/payments/cards/uuid`;

    const res = yield call(apiCallerFetch, 'POST', URL, {});

    const { amount, accountId, invoiceId, currency } = res.data;

    yield put(
      fetchInfoSuccess({
        amount,
        accountId,
        invoiceId,
        currency,
      })
    );
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchInfoError(err.message));
    } else {
      yield put(fetchInfoError('An unknown error occured.'));
    }
  }
}

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchPayment() {
  yield takeLatest(PaymentActionTypes.FETCH_INFO, handleFetchInfo);
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* paymentSaga() {
  yield all([fork(watchPayment)]);
}
