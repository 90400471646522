import { InfoUser, Socials } from '@src/api/ProfileService/DTO/client';

import { SIGN_UP_ACTION_TYPES } from './types';

export interface SignUpStoreSlice {
  phone: string;
  phoneCode: string;
  password: string;
  infoUser: InfoUser;
  landing: string;
  integrationId: string;
  stepsQty: number;
}
export interface SignUpStorePdn {
  contactPerson: string;
  contactPersonPhone: string;
  socialNetwork: string;
  whobelong: string;
}
export interface SignUpStoreSocialNetwork {
  socialNetwork: Socials;
  socialAccount: string;
}

const initialState: SignUpStoreSlice = {
  phone: '',
  phoneCode: '',
  password: '',
  landing: '',
  integrationId: '',
  stepsQty: 2,
  infoUser: {
    address: {
      addressId: 0,
      addressType: '',
      areaFiasId: '',
      city: '',
      cityFiasId: '',
      clientId: 0,
      createDate: '',
      flat: '',
      house: '',
      houseFiasId: '',
      moderationStatus: '',
      postcode: '',
      region: '',
      regionFiasId: '',
      settlementFiasId: '',
      street: '',
      streetFiasId: '',
    },
    clientId: 0,
    mail: '',
    mailValid: true,
    passport: {
      birthDate: '',
      birthPlace: '',
      departmentCode: '',
      documentNumber: '',
      firstName: '',
      issuedDate: '',
      lastName: '',
      patronymic: '',
      serialNumber: '',
    },
    passportSelfie: {
      passportPhotoFieId: 0,
      selfieId: 0,
    },
    pdn: {
      additionalContacts: [
        {
          contactPerson: '',
          contactPersonPhone: '',
          socialNetwork: '',
          whobelong: '',
        },
      ],
      socialNetwork: '',
      socialAccount: '',
    },
    phone: '',
    step: null,
  },
};

export const signUpReducer = (
  state = initialState,
  action: { type: SIGN_UP_ACTION_TYPES; payload: any }
) => {
  switch (action.type) {
    case SIGN_UP_ACTION_TYPES.SET_PHONE: {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case SIGN_UP_ACTION_TYPES.SET_PHONE_CODE: {
      return {
        ...state,
        phoneCode: action.payload,
      };
    }
    case SIGN_UP_ACTION_TYPES.SET_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case SIGN_UP_ACTION_TYPES.SET_INFO_USER: {
      return {
        ...state,
        infoUser: {
          ...action.payload,
        },
      };
    }
    case (SIGN_UP_ACTION_TYPES.SET_FULL_NAME,
    SIGN_UP_ACTION_TYPES.SET_PASSPORT_DATA,
    SIGN_UP_ACTION_TYPES.SET_BIRTH_INFO): {
      return {
        ...state,
        infoUser: {
          ...state.infoUser,
          passport: {
            ...state.infoUser.passport,
            ...action.payload,
          },
        },
      };
    }
    case SIGN_UP_ACTION_TYPES.SET_STEP: {
      return {
        ...state,
        infoUser: {
          ...state.infoUser,
          step: action.payload,
        },
      };
    }

    case SIGN_UP_ACTION_TYPES.SET_STEP_QTY: {
      return {
        ...state,
        stepsQty: action.payload,
      };
    }
    default:
      return state;
  }
};
